header{
	background-color: #FFF;
	background-position: top center;
	background-size: cover;
	box-shadow: 0px 0px 9px -5px #000;
	padding:{
		top: 30px;
		bottom: 30px;
	};
	position: fixed;
	width: 100%;
	z-index: 11;
	top: 0;
}
.logo{
	display: inline-block;
	a{
		display: block;
		width: 100%;
		img{
			width: 200px;
		}
	}
}

.btn-default-submit{
	height: 40px;
	color: $branco;
	background: $azul;
	@include transition(0.3s);
	display: inline-block;
	margin-bottom: 15px;
	cursor: pointer;
	width: 100%;
	border: none;
	font-size: 18px;
	@include radius(50px);
	font-weight: 700;
	text-transform: uppercase;
	&:hover{
		background: $azulescuro;
	}
}

.informacacoes-gerais-header{
	.ligamos-para-voce{
		margin-right: 15px;
		display: inline-block;
		letter-spacing: 0px;
		background: $verde;
		color: $main-color;
		text-align: center;
		height: 30px;
		line-height: 30px;
		padding:{
			left: 15px;
			right: 15px;
		};
		text-transform: uppercase;
		@include radius(10px);
		@include transition(0.3s);
		font:{
			weight: 700;
			size: 13px;
		};
		&:hover{
			background: $verde-escuro;
			color: $main-color;
		}
	}
	.ms{
		display: inline-block;
		li{
			display: inline-block;
			margin:{
				right: 2px;
				left: 2px;
			};
			a{
				display: block;
				width: 100%;
				i{
					@include transition(0.3s);
					color: $main-color;
					font-size: 24px;
					&:hover{
						color: $main-color;
					}
				}
			}
		}
	}
}

.menu{
	width: 100%;
	text-align: right;
	li{
		display: inline-block;
		margin-left: 25px;
		margin-right: 20px;
		a{
			display: inline-block;
			text-align: center;
			text-transform: uppercase;
			color: $main-color;
			letter-spacing: 0px;
			font:{
				weight: 700;
				size: 14px;
			};
		}
		&:last-child{ margin-right: 0; }
	}
}
/*************************************************/
/*************************************************/
/* Mobile */
@media screen and (max-width: 480px) {
.logo{
	max-width:165px;

	img{
		width: 75%;
	}
}

}
@media screen and (min-width: 320px) {
	.informacacoes-gerais-header{
		text-align: right;
		.ligue-agora,
		.whatsapp{
			display: block;
			margin-top: 30px;
			width: 100%;
			span{ display: none; }
		}
	}

	.clique-menu{
		display: inline-block;
		font-size: 30px;
		color: $main-color;
		&:active{
			color: $main-color;
		}
		&:focus{
			color: $main-color;
		}
	}

	.menu{
		text-align: center;
		display: none;
		li{
			margin-left: 0;
			display: block;
			width: 100%;
			text-align: center;
		}
	}
}

@media screen and (min-width: 540px) {
	.informacacoes-gerais-header{
		.ligue-agora,
		.whatsapp{
			margin-top: 50px;
		}
	}
	.clique-menu{
		margin-top: 35px;
		font-size: 40px;
	}
}

@media screen and (min-width: 992px) {
	.menu{
		text-align: right;
		display: block;
		li{
			margin-left: 15px;
      		margin-right: 0px;
			display: inline-block;
			width: auto;
			text-align: center;
		}
	}
	.informacacoes-gerais-header{
		.ligue-agora,
		.whatsapp{
			display: inline-block;
			margin-top: 0;
			width: auto;
			span{ display: inline; }
			margin-right: 12px;
		}
		.ms{
			display: inline-block;
			width: inherit;
		}
	}
}
