// Cores
$branco: #fff;
$preto: #000;
$laranja: #fee21b;
$green: #009600;

$main-color: #222f3e;
$secondary-color: #252525;
$vermelho: #950306;
$cta-color: #950306;
$verde: #5bc106;
$verde-escuro: #087b43;
$azul: #040e87;
$azulescuro: darken(#040e87, 10%);
$cinza:#7e7e7e;
$cta-text-color: #fff;

$menu-text: #fff;

// Mixins
@mixin radius ($radius){
	-moz-border-radius: $radius;
	-webkit-border-radius: $radius;
	border-radius: $radius;
}

@mixin transition ($all){
	-webkit-transition: all ($all) ;
    -moz-transition: all ($all) ;
    -o-transition: all ($all) ;
    transition: all ($all);
}


@mixin box-shadow ($style){
	-moz-box-shadow: ($style);
	-webkit-box-shadow: ($style);
	box-shadow: ($style);
}
