body{
	font:{
		font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; 
		size: 16px;
		weight: 400;
	};
	color: $preto;
	padding-top: 132px;
}

h1, h2, h3, h4, h5{margin: 0px; font-weight: inherit;}

ul{
	padding: 0;
	margin: 0;
	li{
		list-style: none;
	}
}

a{
	text-decoration: none !important;
	color: inherit;
	&:hover{
		text-decoration: none !important;
		color: inherit;
	}
}

section{
	margin-top:50px;
}

.main-bg{
	background-color: $main-color !important;
}

.main-color{
	color: $main-color !important;
}
// Gerais
