.form-contato{
    input{
        @include radius(0);
        margin-bottom: 20px;
        display: inline-block;
        height: 40px;
        border: none;
        border: solid 1px #eee;
    }

    textarea{
        @include radius(0);
        margin-bottom: 20px;
        display: inline-block;
        
        border: solid 1px #eee;
    }

    select{
        margin-bottom: 20px;
        display: inline-block;
        border: none;
        border: solid 1px #eee;
    }

}

.informacoes-da-pagina-de-contato{
    text-align: center;
    margin-bottom: 100px;
    .email-contato,
    .numero-contato,
    .numero-whatsapp{
        margin-right: 30px;
        color: $azul;
        font-size: 14px;
        font-weight: 300;
        strong{ font-weight: 800; }
    }
}

.box-contato{
    padding: 20px;
}

.bg-contato{
    background-image: url('../img/fale-conosco.png');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    padding: 50px 0;
    margin-bottom: 60px;

    .titulo-pagina{
        color: white;
        text-align: left;
    }
}
.bg-trabalhe{
    background-image: url('../img/trabalhe-conosco.png');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    padding: 50px 0;
    margin-bottom: 60px;

    .titulo-pagina{
        color: white;
        text-align: left;
    }
}

.contato p{
    font-size: 32px;
    font-weight: bold;
}
/*************************************************/
/*************************************************/


/* Mobile */

@media screen and (min-width: 320px) {
    .informacoes-da-pagina-de-contato{
        text-align: left;
        span{
            display: inline-block;
            margin-right: 0;
            width: 100%;
            margin-bottom: 20px;
        }
    }
}


@media screen and (min-width: 540px) {

}


@media screen and (min-width: 768px) {

}

@media screen and (min-width: 992px) {
    .informacoes-da-pagina-de-contato{
        text-align: left;
        span{
            display: inline-block;
            margin-right: 0;
            width: inherit;
            margin-bottom: 20px;
        }
    }
}

@media screen and (min-width: 1200px) {

}

@media screen and (min-width: 1400px) {

}
