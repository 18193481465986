.titulo-pagina{
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    display: block;
    margin-top: 50px;
    margin-bottom: 35px;
    font:{
        size: 20px;
        weight: 600;
    };
}

.enviar-contato{
    color: $branco;
    background: $main-color;
    @include transition(0.3s);
    display: inline-block;
    margin-bottom: 15px;
    cursor: pointer;
    border: none;
    font-size: 18px;
    @include radius(50px);
    font-weight: 800;
    text-transform: uppercase;
    &:hover{
        background: $azulescuro;
        color: $branco;
    }
}

.page-link {
    color: #999;
}
.page-item.active .page-link {
    background-color: $azulescuro;
    border-color: $azulescuro;
}

@media screen and (min-width: 320px) {
}
@media screen and (min-width: 576px) {
    
}
@media screen and (min-width: 768px) {
    
}
@media screen and (min-width: 992px) {
    
}
@media screen and (min-width: 1200px) {
    .titulo-pagina{
        margin-bottom: 35px;
        font:{
            size: 30px;
        };
    }
}